html{
    font-size: 20px;
    overflow-x: hidden;
}

body{
  margin: 0;
  color: #424343;
  overflow-y: scroll;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
  width: 100vw;
}

h1,h2,h3{
  font-family: 'Russo One', sans-serif;
}

.app main> div{
  margin-top: 1rem;
}

.appearing{
  animation: text-appear 0.3s 0.05s backwards;
  animation-play-state: paused;
}

.appear-full{
  transition: opacity 0.3s;
  opacity: 0;
}

.appear-full.visable{
  opacity: 1;
}

.appearing.visable{
  animation-play-state: running;
}

.tiles{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  grid-auto-rows: 25vw;
  margin-left: 3rem;
  margin-right: 3rem;
}

.tiles:nth-child(2) .tile{
  background-color: #8d848f;
}

.tiles:nth-child(4) .tile{
  background-color: #4f5978;
}

.tiles:nth-child(6) .tile{
  background-color: #6b665c;
}


.banner img, .tile img{
  object-fit: cover;
  object-position: center 64%;
  height: 100%;
  width: 100%;
}

.app main > .full-size{
position: fixed;
width: 100vw;
top: 0;
left: 0;
height: 100vh;
z-index: 4;
display: flex;
align-items: center;
justify-content: center;
background-color: white;
margin: 0;
overflow: hidden;
cursor: zoom-out;
}

.full-size img{
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.full-size.visable{
  opacity: 1;
}

.full-size.not-visable{
  pointer-events: none;
}

.app .loading-container{
  position: fixed;
  left: 0;
  top:0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  pointer-events: none;
}

.loading-container .loading-shape{
  border-radius: 100%;
  background-color: #520303;
  animation: poping 1.8s infinite;
  opacity: 0.7;
  height: 20px;
  width: 20px;
  transform: scale(0);
  margin: 2px;
}

.loading-container .shape2{
  animation-delay: 0.6s;
  background-color: rgb(53, 47, 69);
}

.loading-container .shape3{
    animation-delay: 1.2s;
}

.full-size.visable + .loading-container.src-setted .loading-shape, .loading-container.not-src .loading-shape{
  animation: pause;
  display: none;
}

.cross{
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  height: 40px;
  width: 40px;
  background: url("./cross.svg") no-repeat center / contain;
  filter: drop-shadow(2px 2px 3px #0008) drop-shadow(-2px -2px 3px #0008);
}

.cross.revert{
  background: url("./cross-revert.svg") no-repeat center / contain;
}

.tiles .tile:nth-child(9) .cross{
  background: url("./doc.svg") no-repeat center / contain;
}

.go-to-button{
  width: 60px;
  height: 60px;
  position: fixed;
  margin: 1rem;
  right: 0;
  z-index: 10;
  background-color: transparent;
  background-size: contain;
  border: none;
  cursor: pointer;
  filter: drop-shadow(2px 2px 3px #0008) drop-shadow(-2px -2px 3px #0008);
  background-repeat: no-repeat;
  transition: transform 0.3s;
  outline: none
}

.go-to-top{
top: 0;
background-image: url("toTop.svg");
transform: scale(0);
}

.go-to-contact{
  top: calc(100vh - 60px - 2rem);
  background-image: url("toContact.svg");
}

.go-to-button:focus{
  transform: scale(1.2);
}


.app.center .go-to-top, .app.bottom .go-to-top{
  transform: scale(1);
}

.app.bottom .go-to-contact{
  transform: scale(0);
}

@media (max-width:1024px) {
  html{
      font-size: 16px;
  }
  .go-to-button{
    width: 50px;
    height: 50px;
  }

  .tiles{
    grid-template-columns: 1fr;
      grid-auto-rows: 50vw;
  }
  @media (orientation:landscape){
    html{
        font-size: 14px;
    }
  }
}

@media (max-width:800px) {
  html{
      font-size: 12px;
  }
}

@keyframes text-appear {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

@media (hover:hover) {
  .go-to-button:hover{
    transform: scale(1.2);
  }
}
