.banner{
  width: 100%;
  height: 500px;
  position: relative;
}

.banner h2{
  margin: 0;
  margin-left: 4rem;
  color: #fff;
  display: flex;
  font-size: 3.65rem;
  position: absolute;
  top: 0;
  height: 100%;
  align-items: center;
  text-shadow: 2px 2px 10px #0005, -2px -2px 10px #0005;
}

.banner h2 span{
  display: block;
}

@media  (max-width:1024px) {
  .banner{
    height: 200px;
  }
}
