.form-container{
  display: flex;
  text-shadow: 2px 2px 10px #0005, -2px -2px 10px #0005;
}
.form-side{
  width: 50vw;
  height: 50vw;
  max-height: 600px;
  flex: 1;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.form-container .right-side{
  flex-direction: column;
  text-shadow: none;
  justify-content: flex-start;
  align-items: flex-start;
}

.form-container .form-sended{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #fff;
  transition: opacity 0.5s;
  z-index: 6;
  pointer-events: none;
}

.form-container .form-sended.send{
  opacity: 1;
  pointer-events: all;
}


.form-container .form-sended .sign{
font-size: 10rem
}
.form-container .form-sended .sended-title{
  max-width: 90%;
}

.form-container .form-sended .error, .form-container .form-sended .is-not-ok{
display: none
}

.form-container .form-sended.error .error, .form-container .form-sended.error .is-not-ok{
display: block
}

.form-container .form-sended.error .ok, .form-container .form-sended.error .is-ok{
display: none
}


.form-container .right-side form{
  margin-left: 3rem;
  position: relative;
  width: 17rem;
}
.form-container .right-side form a{
  color: #bd1321;
  text-decoration: none;
}

.form-container .right-side .form-group, .form-container .right-side h3{
  position: relative;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.form-container .right-side .form-group label{
  position: absolute;
  left: 1rem;
  top: 1rem;
  transition: opacity 0.3s;
  opacity: 0;
}

.form-container .right-side .form-group textarea{
  height: 4rem;
}

.form-container .right-side .form-group input, .form-container .right-side .form-group textarea{
  border: 1px solid #0008;
}

.form-container .right-side .form-group input[data-value=""] + label, .form-container .right-side .form-group input:not([data-value]) + label,
.form-container .right-side .form-group textarea[data-value=""] + label, .form-container .right-side .form-group textarea:not([data-value]) + label  {
  opacity: 1;
}

.form-container .right-side .form-group input:focus + label, .form-container .right-side .form-group textarea:focus + label {
  opacity: 0;
}

.form-container .right-side .form-group-checkbox{
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
}

.form-container .right-side .form-group-checkbox input{
height: 1.5rem;
width: 1.5rem;
margin-right: 5px;
}

.form-container .right-side .form-group-checkbox label{
  font-size: 10px;
}

.form-container .right-side .form-text{
border-radius: 5px;
width: calc(100% - 2rem);
padding: 1rem;
}

.form-container .right-side form .btn-submit, .form-container .form-sended button{
  width: 50%;
  position: relative;
  left: 50%;
  padding: 1rem;
  background-color: #bd1321ff;
  color: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 1rem
}

.form-container .right-side form .btn-submit.waiting::after{
  display: block;
  content: "";
  color:transparent;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  border: 5px solid;
  border-top: 5px solid #fff9;
  border-bottom: 5px solid #fff9;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}
.form-container .right-side form .btn-submit.waiting::before{
  position: absolute;
  content: "";
  background-color: #fff5;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  width: 10px;
  height: 10px;
}

.form-container .right-side form > div:last-child{
  position: relative;
}

.form-container .right-side form fieldset > div:last-child::after{
  content: "* - Установіть прапорець Captcha";
  color: red;
  display: none;
}

.form-container .right-side form fieldset.no-captcha > div:last-child iframe{
  box-shadow: 2px 2px 0 red, -2px -2px 0 red;
}

.form-container .right-side form fieldset.no-captcha > div:last-child::after{
  display: block;
}

.form-container .right-side .form-sended button{
  position: static;
  left: 0;
}

.form-container .right-side fieldset{
  border: none;
  padding: 0;
  margin: 0;
}


.form-side img{
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 90%;
  z-index: -1;
}

.form-container .caption h2{
  margin-bottom: 0;
  font-weight: normal;
  font-size: 3rem;
  text-transform: uppercase;
}

.form-container .caption {
  max-width: 60%;
  font-size: 1.2rem;
  color: #fff;
}

@media (max-width:1024px) {
  .form-container{
    flex-direction: column;
  }
  .form-container .form-side{
    width: 100%;
  }

  .form-container .left-side{
    width: 100%;
    height: 200px;
  }

  .form-container .right-side form {
    margin-left: 3rem;
    margin-right: 3rem;
    width: calc(100% - 6rem);
  }

  .form-container .caption h2{
    font-weight: normal;
    font-size: 1.5rem;
    text-transform: uppercase;
  }

  @media (orientation:landscape) {

    .form-container .caption h3{
      font-weight: normal;
      font-size: 2rem;
      text-transform: uppercase;
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
