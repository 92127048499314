.footer{
  display: flex;
  height: 150px;
  position: relative;
  margin-top: 1rem;
}

footer .footer-side{
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
}

footer .left-footer .uk-flag{
  width: 70px;
  margin-left: 3rem;
  margin-right: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

footer .right-footer {
  flex-direction: column;
  justify-content: center;

}
footer .left-footer .bar{
  height: 20px
}

footer .left-footer .up{
  background-color: #005BBB;
}

footer .left-footer .down{
  background-color: #FFD500;
}

footer a{
  text-decoration: none;
  color: inherit;
}

footer a:hover, footer a:focus{
  opacity: 0.5;
}

footer .left-footer .footer-note{
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 0.8rem;
}

footer .left-footer .footer-note > a{
color: #bd1321
}

footer .right-footer .logo-modular-wrapper{
  width: 80%;
  height: 80%;
}

footer .right-footer .logo-modular{
width: 100%;
height: 100%;
}

.cookie{
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  width: calc(100 - 2rem);
  text-align: center;
}

.pm-logos{
  display: grid;
  width: 100%;
  grid-template-columns: 25% 25% 25% 25%;
  position: relative;
  align-items: center;
}

.pm-logos:hover, .pm-logos:focus{
  opacity: 1;
}

.pm-logos img{
  width: 100%;
}

@media (max-width:1024px) {
  footer .left-footer .uk-flag{
    width: 30px;
    margin-left: 1rem;
    margin-right: 0.5rem;
  }
  footer .left-footer .bar{
    height: 8px
  }
}
