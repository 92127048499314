.tile{
 position: relative;
}

.tile img{
  cursor: pointer;
}

.tile .caption{
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 10%;
  margin-left: 10%;
  max-width: 80%;
  color: #fff;
  text-shadow: 2px 2px 10px #0005, -2px -2px 10px #0005;
  pointer-events: none;
}

a.tile .caption{
pointer-events: none;
position: absolute;
margin: 0;
top: 0;
max-width: 100%;
width: 100%;
display: flex;
justify-content: center;
align-items: flex-start;
flex-direction: row;
text-align: center;
}

.tile.no-image .caption{
  pointer-events: all;
}

a.tile .caption h3{
  margin: 0.5rem
}

.tile .caption ul{
  font-size: 0.8rem;
  padding: 0;
  margin: 0;
  line-height: 1.4;
}

.tile .caption h3{
  margin-bottom: 0;
  font-weight: normal;
  font-size: 1.5rem;
  text-transform: uppercase;
}

a .tile .caption{
  z-index: 10;
}

.tile .cross{
  display: none
}
.tile img + .cross{
  display: block;
}

.tile .cross, .viewer .cross{
  pointer-events: none
}

.tile .viewer{
  position: absolute;
  top: 0;
  right: 0;
  height: 140%;
  width: 140%;
  background-color: #fff;
  transition: transform 0.3s, opacity 0.3s;
  pointer-events: none;
  opacity: 0;
  z-index: 3
}

.tile .viewer.open{
  opacity: 1;
  transform: scale(4);
}

.tile.no-image::after{
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
background: url("./background/background_zyyk0w_c_scalew_833.jpg") no-repeat 40% top / cover;
content: "";
opacity: 0.1;
}

.tile:nth-child(5).no-image::after{
background-size: 170%;
background-position: 50% 100%;
}

@media (max-width:1524px) {
  .tile.no-image::after{
      background-image: url("./background/background_zyyk0w_c_scalew_581.jpg")
    }
}

@media (max-width:1338px) {
  .tile .caption h3{
    margin-top: 0.5rem;
  }
  .tile .caption{
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
}

@media (min-width:1195px) {
 .tile .caption ul{
       font-size: 0.9rem
 }
}

@media (min-width:1295px) {
 .tile .caption ul{
       font-size: 1rem
 }
}

@media (max-width:1095px) {
 .tile .caption ul{
       line-height: 1.1rem
 }
}

@keyframes poping {
  25%{
    transform: scale(1);
  }
  50%{
    transform: scale(0);
  }
  100%{
    transform: scale(0);
  }
}

@media (hover:hover) {
  .tiles .tile img:hover{
    opacity: 0.5
  }
}

@media (max-width:1024px) {
  .tile.no-image::after{
      background-image: url("./background/background_zyyk0w_c_scalew_1024.jpg")
    }
  .tile .caption h3{
    margin: 0;
  }
  .tile .caption{
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 80%;

  }

  .tile .caption ul{
    font-size: 1rem;
    margin: 0;
    line-height: 1.4;
  }

  .tiles3 .tile:nth-child(1){
    order: 0;
  }
  .tiles3 .tile:nth-child(2){
    order: 1;
  }
  .tiles3 .tile:nth-child(3){
    order: 3;
  }
  .tiles3 .tile:nth-child(4){
    order: 2;
  }
  .tiles3 .tile:nth-child(5){
    order: 4;
  }
  .tiles3 .tile:nth-child(6){
    order: 5;
  }
  .tiles3 .tile:nth-child(7){
    order: 6;
  }
  .tiles3 .tile:nth-child(8){
    order: 7;
  }
  .tiles3 .tile:nth-child(9){
    order: 8;
  }

  @media (orientation:landscape) {
    .tile .caption ul{
      font-size: 1.5rem;
    }
    .tile .caption h3{
      margin-bottom: 0;
      font-weight: normal;
      font-size: 2rem;
      text-transform: uppercase;
    }
  }
}

@media (max-width:833px) {
  .tile.no-image::after{
      background-image: url("./background/background_zyyk0w_c_scalew_833.jpg")
    }
}

@media (max-width:581px) {
  .tile.no-image::after{
      background-image: url("./background/background_zyyk0w_c_scalew_581.jpg")
    }
}
