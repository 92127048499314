header{
  height: 100vh;
  width: 100vw;
  position: relative;
  color:#fff;
  text-shadow: 2px 2px 10px #0005, -2px -2px 10px #0005;
}

header .caption{
  position: absolute;
  right: 15%;
  top: 29.5%;
  text-align: right;
  z-index: 1;
  max-width: 65%;
  font-size: 1.2rem;
}

h1{
  margin-top: 0;
  font-size: 4rem;
  font-weight: normal;
  margin-bottom: 1rem;
  line-height: 0.9;
  text-transform: uppercase;
  animation: text-appear-right 0.3s 0.1s backwards;
}

h1 span{
  display: block
}

header .caption-inner{
    animation: text-appear 0.3s 0.6s backwards;
}

header .caption ul{
  list-style:none;
  margin:0;
  margin-top: 0.5rem;
  margin-right: 1rem;
  padding: 0;
  line-height: 1.2
}

header .caption ul, header .caption > div > sp> div > span{
    text-shadow: 2px 2px 10px #0009, -2px -2px 10px #0009;
}

header .caption ul li{
  position: relative;
}

header .caption ul > li::after {
  content: "-";
  position: absolute;
  right: -1rem;
  top: 0;
}

header .background{
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: bottom;
  z-index: -1;
  opacity: 0;
}

header .background.visable{
  animation: text-appear 0.3s both;
}

header .wrapper-vertical{
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
}

header .wrapper-menu{
  justify-content: flex-start;
  top: 0;
  z-index: 1;
}

header .wrapper-button{
  bottom: 5px;
  z-index: 1;
}

header .wrapper-button button{
  background-color: #fff;
  position: relative;
  padding: 0.8rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: block;
  font-size: 1.5rem;
  border-radius: 20px;
  border: none;
  color: #424343;
  cursor: pointer;
}

header .wrapper-button .arrow-container{
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -30%;
  display: flex;
  justify-content: center;
  animation: up-down 0.5s infinite alternate linear;
}

header .wrapper-button .arrow-container::after{
  content: "»";
  transform: rotate(90deg);
}

header .logo{
  margin-top: 3rem;
  margin-left: 3rem;
}

header .logo img{
  transition: transform 0.3s;
}

header .logo:focus img{
  transform: scale(1.1);
}

header nav{
  left: 0;
  top: calc(29.5% - 2.5rem);
  position: absolute;
  z-index: 1;
  padding-right: 1.5rem;
  background-color: #0008;
  animation: text-appear-right 0.3s 1s backwards linear;
}

header .menu{
  margin-top: 2.5rem;
  position: relative;
  margin-left: 2rem;
  font-size: 1.5rem;
  list-style: none;
  text-align: right;
  line-height: 1.5rem;
  font-family: 'Russo One', sans-serif;
}

header .menu > li{
  text-transform: lowercase;
  margin-bottom: 2.5rem;
  position: relative;
  transition: transform 0.2s;
}

header .menu li::after{
  position: absolute;
  content: "»";
  transform: scale(-1);
  height: 100%;
  align-items: center;
  display: flex;
  font-size: 2.5rem;
  right: -2rem;
  top: 0.3rem;
  pointer-events: none
  /* color: #bd1321ff; */
}

header .menu a{
text-decoration: none;
color: inherit;
/* color: #bd1321ff; */
}

header .menu span{
  display: block;
}

header .menu li:focus-within{
  transform: translate(-5px,0);
}
header button:focus{
  background-color: #434243;
  color: #fff;
}

header .caption ul > li:first-child{
  position: relative;
  right: -1rem;
}

header .caption ul > li:first-child::after{
  display: none;
}

@media (hover:hover){
  header .logo:hover img{
    transform: scale(1.1);
  }
  header button:hover{
    background-color: #434243;
    color: #fff;
  }
 header .menu li:hover{
    transform: translate(-5px,0);
  }
}

@media (max-width:1260px) {
  header .caption{
        right: 10%;
          max-width: 60%
  }
}

@media (max-width:1100px) {
  header .caption{
        right: 5%;

  }
}

@media (max-width:1024px) {
  header .background{
        object-position: center;
  }
}

@media (max-width:800px) {
  header .wrapper-menu{
    justify-content: center;
  }
  header{
    height: auto;
  }

  header .background{
    height: 400px;
  }
  header h1{
    margin-bottom: 1rem;
  }

  header .caption{
    right: 3rem;
  }

  header .menu{
    top: 20%;
    padding-left: 0;
  }

  header .caption ul{
    font-size: 1.2rem;
  }
  header .logo{
      margin-top: 0.5rem;
      margin-left: 0;
  }

  @media (orientation:portrait){

  header nav{
      background-color: transparent;
  }

    header .background{
      height: 700px;
    }

    header .caption ul{
      margin-left: 2rem;
      margin-right: 2rem;
    }

    header .caption{
      top:11rem;
      right: auto;
      max-width: none;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: left;
    }
    header .caption h1{
      text-align: center;
    }

    header .caption ul > li::after {
      content: none;
    }

    header .caption ul > li::before {
      content: "-";
      position: absolute;
      left: -1rem;
      top: 0;
    }

    header .caption > div > span{
      line-height: 30px;
      margin: 0;
      display: block;
    }

    header nav{
      height: auto;
      top:400px;
      display: flex;
      width: 100%;
      justify-content: center;
    }

    header nav .menu{
      margin: 0;
      text-align: left;
    }

    header .menu li::after{
    transform: none;
    right: 0;
    left: -2rem;
    top: 0;
    }

    header .caption ul > li:first-child{
      position: relative;
      left: 0
    }

    header .caption ul > li:first-child::before{
      display: none;
    }

    header .menu li{
      margin: 1rem;
    }
    header .menu span{
      display: inline;
    }
  }
}


@keyframes text-appear-right {
  from{
    opacity: 0;
    transform: translate(20px);
  }
  to{
    opacity: 1;
  }
}

@keyframes up-down {
  to{
    transform: translate(0,5px);
  }
}
