.video-section{
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  }

.video-wrapper{
  width: 100%;
  height: 100%;
  max-width: 1400px;
  position: relative;
}

.video-section video{
  width: 100%;
  height: 100%;
  outline: none;
  cursor: pointer;
  object-fit: contain;
}

.video-section video:focus{
  opacity: 0.5;
}

.video-section .mute{
  bottom: auto;
  top: 1rem;
  background-image: url("../mute.svg");
}

.video-section .mute.loud{
  background-image: url("../mute2.svg");
}

.video-section .video-caption{
color: #fff;
position: absolute;
left: 0;
top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  flex-direction: column;
  pointer-events: none;
  text-shadow: 2px 2px 10px #0005, -2px -2px 10px #0005;
  transition: opacity 0.3s;
}

.video-section.playing .video-caption{
  opacity: 0;
}


.video-section .video-caption .play{
  height: 70px;
  width: 70px;
  position: relative;
  border-radius: 100%;
  border: 5px #fff solid;
}

.video-section .video-caption .play::after
{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 50px;
  transform: translate(2px,-2px);
  content: "▸";
  display: flex;
  align-items: center;
  justify-content: center;
}

@media  (max-width:1024px) {
  .video-section{
    width: calc(100% - 6rem);
    margin-left: 3rem;
  }
}

@media  (max-width:500px) {
  .video-section .video-caption .play{
    height: 50px;
    width: 50px;
  }
}
